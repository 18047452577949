












import { ActiveCall, RemoteStream } from '@/store/modules/activeCall/models'
import { Component, Vue, Ref, Watch, Prop } from 'vue-property-decorator'

@Component
export default class CallAudio extends Vue {
  @Prop({
    type: Object,
    required: true,
  }) readonly call!: ActiveCall

  @Ref() readonly audio!: HTMLVideoElement

  get volume () {
    return this.call.outputVolume
  }

  get streams (): Array<RemoteStream | MediaStream> {
    return this.call.type === 'video_multistream'
      ? this.call.multistream.audio
      : this.call.remoteStreams
  }

  @Watch('streams', { immediate: true })
  onStreamsChanged (n: CallAudio['streams']) {
    n.forEach((s, i) => {
      this.$nextTick(() => {
        const aEls = this.$refs[`audio-${i}`] as HTMLAudioElement[]
        const aEl = aEls[0]
        if (!aEl) return

        const stream = 'stream' in s ? s.stream : s
        if (aEl.srcObject === stream) return

        aEl.volume = this.volume
        aEl.srcObject = stream
      })
    })
  }

  @Watch('volume', { immediate: true })
  onVolumeChanged (value: number | undefined) {
    this.audio && (this.audio.volume = value ?? 0)
    this.streams.forEach(s => {
      const stream = 'stream' in s ? s.stream : s
      stream.getAudioTracks().forEach(t => (t.enabled = !!value))
    })
  }
}
